.goBtn:hover, .goBtn:visited, .goBtn:link, .goBtn:active, .homeBtn:hover, .homeBtn:visited, .homeBtn:link, .homeBtn:active {
    text-decoration: none;
}

.homeBtn, .homeBtn:hover {
    color: black;
    font-size: larger;
}

.goBtn {
    color: black;
    margin-left: 5pt;
}

#setWidth {
    margin-left: 6.5%;
    margin-right: 6.5%;
    padding-top: 0pt;
    padding-bottom: 0pt;
}
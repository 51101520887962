/* Only for when no model is found */
.setMargins {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  text-align: center;
}

.contact {
  margin-top: 10pt;
}

.discHeader {
  font-size: small;
}

.modelRow {
  cursor: pointer;
}

.logo {
  height: 70px;
  width: 180px;
}

.addNewJobBtn {
  margin-top: 0pt;
  margin-bottom: 10pt;
  padding-top: 2pt;
  padding-bottom: 2pt;
  padding-left: 12pt;
  padding-right: 12pt;
  font-size: smaller;
}

.addNewJob {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#addModelBtn {
  margin-top: 18pt;
  margin-bottom: 18pt;
}

#modelsTable {
  margin-top: 15pt;
  margin-bottom: 20pt;
}

.inlineParent {
  overflow-x: auto;
  overflow-y: hidden;
}

.inlineChild {
  display: inline-block !important;
}

.filterDiv {
  margin-top: 5pt;
  margin-bottom: 5pt;
}

#downloadBtn {
  float: right !important;
}

#addCommentBtn {
  float: left !important;
  margin-bottom: 15pt;
}

.filterLabel {
  width: 20%;
}

.filterSelect {
  width: 10%;
}

img {
  cursor: pointer;
}

#editCommentBtn {
  margin-right: 20pt;
}

.flex-centre {
  display: flex;
  width: 220px;
}

#editModelBtn {
  float: right;
  margin-top: 12px;
}

.jobDescription {
  font-size: larger;
}

.descriptionInfo {
  margin-bottom: 10px;
}

.welcome {
  margin-right: 15px;
}

.comments {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 20pt;
  white-space: pre-line;
}

img {
  flex: 1;
  width: 15pt;
  height: 15pt;
}

#filter {
  margin-right: 70%;
}

#export {
  float: right;
}

.description {
  margin-left: 10%;
  margin-right: 10%;
}

p {
  margin-bottom: 2pt;
  margin-top: 2pt;
}
